import * as React from 'react'

import { Lawyer, BlockNewsPublicationsGridType, BlockHeader, Expertise } from '../../interfaces/Interfaces'
import { TweenMax } from 'gsap'
import ModRichText from '../common/ModRichText'
import ModSvg from '../common/ModSvg'
import ModExpertiseTeaser from '../common/ModExpertiseTeaser'
import ModNewsPublicationsTeaser from '../common/ModNewsPublicationsTeaser'
import ModAnimation from 'components/common/ModAnimation'
import { createBlockNewsPublicationsGrid, stringToBlockTitleObject } from 'common/CmsUtils'
import ModBlockNewsPublicationsGrid from 'components/blocks/ModBlockNewsPublicationsGrid'
import ModBlockQuote from 'components/blocks/ModBlockQuote'
import ModBlockHeader from 'components/blocks/ModBlockHeader'
import { getLayerPositionByOffice } from 'components/common/Utils'
import { hasRichTextContent, richTextToPlainText, stringToRichText } from 'common/Utils'
import ModPrintView from 'components/common/ModPrintView'
import ModImgTag from 'components/common/ModImgTag'
import ModBlockTitle from 'components/blocks/ModBlockTitle'
import { FormattedMessage, injectIntl } from 'react-intl'

export interface ModTemplateLawyerState {}

export interface ModTemplateLawyerProps {
  lawyer: Lawyer
  currentLanguage: string
  intl: any
}

class ModTemplateLawyer extends React.Component<ModTemplateLawyerProps, ModTemplateLawyerState, {}> {
  constructor(props) {
    super(props)
    this.state = {}

    this.scrollToElement = this.scrollToElement.bind(this)
  }

  componentDidMount() {
    const { ScrollToPlugin } = require('gsap/all')
  }

  scrollToElement(el) {
    el.preventDefault()
    TweenMax.to(window, 1, {
      scrollTo: { y: el.target.hash, offsetY: 100, autoKill: false },
    })
  }

  getByOrder(expertise: { fixOrder: boolean; key: Expertise[]; leader: Expertise[]; member: Expertise[] }, category: string) {
    const practicesArrayKey = this.sortExpertise(
      expertise.key.filter((item) => item.category == category),
      expertise.fixOrder
    )
    const practicesArrayLeader = this.sortExpertise(
      expertise.leader.filter((item) => item.category == category),
      expertise.fixOrder
    )
    const practicesArrayMember = this.sortExpertise(
      expertise.member.filter((item) => item.category == category),
      expertise.fixOrder
    )

    return practicesArrayKey.concat(practicesArrayLeader, practicesArrayMember)
  }

  sortExpertise(expertise: Expertise[], fixOrder) {
    if (!fixOrder) {
      return expertise.sort((a, b) => {
        const aTitle = richTextToPlainText(a._i18nCL?.title)
        const bTitle = richTextToPlainText(b._i18nCL?.title)
        return aTitle > bTitle ? 1 : bTitle > aTitle ? -1 : 0
      })
    }

    return expertise
  }

  render() {
    const { lawyer, currentLanguage, intl } = this.props
    const pdfFileName = `${lawyer.contact.firstName.toLocaleLowerCase()} ${lawyer.contact.lastName.toLocaleLowerCase()}.pdf`

    // SORT ARRAY FOR EXPERTISE
    let practicesArray = []
    if (this.props.lawyer.expertise_ordered) {
      practicesArray = this.getByOrder(this.props.lawyer.expertise_ordered, 'practice')
    }

    let sectorsArray = []
    if (this.props.lawyer.expertise_ordered) {
      sectorsArray = this.getByOrder(this.props.lawyer.expertise_ordered, 'sector')
    }

    const getLawyerDescription = () => {
      if (lawyer._i18nCL && lawyer._i18nCL.description && hasRichTextContent(lawyer._i18nCL.description)) {
        return (
          <div className="grid align-center lawyer__info__element">
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <ModRichText richText={lawyer._i18nCL.description}></ModRichText>
            </ModAnimation>
          </div>
        )
      } else {
        return null
      }
    }

    const getLawyerRecentWorkHighlights = () => {
      if (lawyer._i18nCL && lawyer._i18nCL.recentWorkHighlights && hasRichTextContent(lawyer._i18nCL.recentWorkHighlights)) {
        return (
          <div className="grid align-center lawyer__info__element">
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <span className="label">
                <FormattedMessage id="lawyer.highlights" defaultMessage="Recent Work Highlights" />
              </span>

              <ModRichText richText={lawyer._i18nCL.recentWorkHighlights} />
            </ModAnimation>
          </div>
        )
      } else {
        return null
      }
    }

    const getLawyerCurriculum = () => {
      if (lawyer._i18nCL && lawyer._i18nCL.curriculum && hasRichTextContent(lawyer._i18nCL.curriculum)) {
        return (
          <div className="grid align-center lawyer__info__element">
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <span className="label">
                <FormattedMessage id="lawyer.curriculum" defaultMessage="Curriculum" />
              </span>
              <ModRichText richText={lawyer._i18nCL.curriculum} />
            </ModAnimation>
          </div>
        )
      } else {
        return null
      }
    }

    const getLawyerLanguages = () => {
      if (lawyer.languages) {
        return (
          <div className="grid align-center lawyer__info__element">
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <span className="label">
                <FormattedMessage id="lawyer.languages" defaultMessage="Languages" />
              </span>
              {languages}
            </ModAnimation>
          </div>
        )
      } else {
        return null
      }
    }

    const languages = lawyer.languages.map((item, index) => {
      return (
        <span key={index}>
          {index != 0 ? ', ' : ''}
          {item._i18nCL.title}
        </span>
      )
    })

    const getLawyerMemberships = () => {
      if (lawyer._i18nCL && lawyer._i18nCL.memberships && hasRichTextContent(lawyer._i18nCL.memberships)) {
        return (
          <div className="grid align-center lawyer__info__element">
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <span className="label">
                <FormattedMessage id="lawyer.memberships" defaultMessage="Memberships and Public Offices" />
              </span>
              <ModRichText richText={lawyer._i18nCL.memberships} />
            </ModAnimation>
          </div>
        )
      } else {
        return null
      }
    }

    const getPractices = () => {
      if (practicesArray.length > 0) {
        return (
          <div className="grid align-center lawyer__expertise__element">
            <div className="cell small-24 large-20 xlarge-18">
              <ModAnimation>
                <span className="label">
                  {practicesArray.length > 1
                    ? intl.formatMessage({ id: 'global.practice-areas', defaultMessage: 'Practice Areas' })
                    : intl.formatMessage({ id: 'global.practice-area', defaultMessage: 'Practice Area' })}
                </span>
              </ModAnimation>
              <div className={'grid expertise expertise--practices'}>{practices}</div>
            </div>
          </div>
        )
      } else {
        return null
      }
    }

    const practices = practicesArray.map((item, index) => {
      return (
        <ModAnimation className="cell small-24 medium-12 large-8" key={index}>
          <ModExpertiseTeaser content={item} />
        </ModAnimation>
      )
    })

    const getSectors = () => {
      if (sectorsArray.length > 0) {
        return (
          <div className="grid align-center lawyer__expertise__element">
            <div className="cell small-24 large-20 xlarge-18">
              <ModAnimation>
                <span className="label">
                  {sectorsArray.length > 1
                    ? intl.formatMessage({ id: 'global.sectors', defaultMessage: 'Sectors' })
                    : intl.formatMessage({ id: 'global.sector', defaultMessage: 'Sector' })}
                </span>
              </ModAnimation>
              <div className={'grid expertise expertise--sector'}>{sectors}</div>
            </div>
          </div>
        )
      } else {
        return null
      }
    }

    const sectors = sectorsArray.map((item, index) => {
      return (
        <ModAnimation className="cell small-24 medium-12 large-8" key={index}>
          <ModExpertiseTeaser content={item} />
        </ModAnimation>
      )
    })

    const getLawyerOffices = lawyer.offices.map((item, index) => {
      if (item._i18nCL) {
        return (
          <ModAnimation className="cell small-24 medium-20 large-8 lawyer__contact__element" key={index}>
            <p>
              {item._i18nCL.address.name && (
                <>
                  <strong>{item._i18nCL.address.name}</strong> <br />{' '}
                </>
              )}
              {item._i18nCL.address.street && (
                <>
                  {item._i18nCL.address.street} <br />{' '}
                </>
              )}
              {item._i18nCL.address.city && (
                <>
                  {item._i18nCL.address.city} <br />{' '}
                </>
              )}
              {item._i18nCL.address.country && <>{item._i18nCL.address.country} </>}
            </p>
          </ModAnimation>
        )
      } else {
        return null
      }
    })

    const getLawyerAssistants = () => {
      if (lawyer.assistants.length > 0) {
        return (
          <React.Fragment>
            <div className="grid align-center lawyer__assistant">
              <ModAnimation className="cell small-24 large-20 xlarge-18">
                <span className="label">
                  {lawyer.assistants.length > 1 ? (
                    <FormattedMessage id="lawyer.assistants" defaultMessage="Assistants" />
                  ) : (
                    <FormattedMessage id="lawyer.assistant" defaultMessage="Assistant" />
                  )}
                </span>
              </ModAnimation>
            </div>
            <div className="grid align-center">
              <div className="cell small-24 large-20 xlarge-18">
                <div className="grid">{assistants}</div>
              </div>
            </div>
          </React.Fragment>
        )
      } else {
        return null
      }
    }

    const assistants = lawyer.assistants.map((item, index) => {
      return (
        <ModAnimation className="cell small-24 medium-12 large-8 lawyer__assistant__element rich-text" key={index}>
          <strong>
            {item.contact.firstName} {item.contact.lastName}
          </strong>
          <br />
          {item.contact.phone && (
            <>
              {' '}
              {intl.formatMessage({ id: 'lawyer.phoneNumber', defaultMessage: 'P' }) + ': '}{' '}
              <a href={'tel:' + item.contact.phone}>{item.contact.phone}</a>
              <br />{' '}
            </>
          )}
          {item.contact.fax && (
            <>
              {' '}
              {'F: ' + item.contact.fax} <br />{' '}
            </>
          )}
          {item.contact.mobile && (
            <>
              {item.contact.mobile} <br />{' '}
            </>
          )}
          {item.contact.email && (
            <>
              <a href={'mailto:' + item.contact.email}>{item.contact.email}</a>
            </>
          )}
        </ModAnimation>
      )
    })

    const getNews = lawyer.news.map((item, index) => {
      return (
        <div className="cell small-24 medium-12 large-12" key={index}>
          <ModNewsPublicationsTeaser currentLanguage={currentLanguage} content={item} type="news" />
        </div>
      )
    })

    const getPublications = lawyer.publications.map((item, index) => {
      return (
        <div className="cell small-24 medium-12 large-12" key={index}>
          <ModNewsPublicationsTeaser currentLanguage={currentLanguage} content={item} type="publications" />
        </div>
      )
    })

    const vCardLink = `/api/vcard?slug=${lawyer._i18nCL.slug}&lang=${currentLanguage}`

    const positionTitle = getLayerPositionByOffice(lawyer.offices, lawyer.position)

    const lawyerHeader: BlockHeader = {
      _id: lawyer._id,
      internalDescription: lawyer.internalDescription,
      items: [
        {
          _i18nCL: {
            subtitle: positionTitle ? positionTitle : lawyer.position._i18nCL.title,
            title: stringToRichText(
              (lawyer.contact.academicTitle ? lawyer.contact.academicTitle + ' ' : '') +
                lawyer.contact.firstName +
                ' ' +
                lawyer.contact.lastName
            ),
          },
          image: lawyer.headerImage,
        },
      ],
    }

    const hero = <ModBlockHeader content={lawyerHeader} bigHeader={true}></ModBlockHeader>

    const heroPrint = (
      <div className={'hero-print'}>
        <ModImgTag className={'hero-print__image'} imgObject={lawyer.headerImage} width={2568} height={1440} visible={true} />
        <div className={'hero-print__content'}>
          <div>
            <span className={'hero-print__subtitle label'}>{lawyer.position._i18nCL.title}</span>
            <h1 className={'heor-print__title'}>
              {(lawyer.contact.academicTitle ? lawyer.contact.academicTitle + ' ' : '') +
                lawyer.contact.firstName +
                ' ' +
                lawyer.contact.lastName}
            </h1>
          </div>
        </div>
      </div>
    )

    const content = (
      <React.Fragment>
        <div className="lawyer__nav grid align-middle">
          <ModAnimation className="cell small-24 medium-20 large-20 large-offset-1  hero__text">
            <ul className={'menu'}>
              <li>
                <a href="#lawyer-about" onClick={this.scrollToElement}>
                  {intl.formatMessage({ id: 'lawyer.about', defaultMessage: 'About' })}
                </a>
              </li>
              {lawyer.news.length > 0 && (
                <li>
                  <a href="#lawyer-deals-cases" onClick={this.scrollToElement}>
                    {intl.formatMessage({ id: 'lawyer.deals', defaultMessage: 'Deals, Cases & News' })}
                  </a>
                </li>
              )}
              {lawyer.contact && (
                <li>
                  <a href="#lawyer-contact" onClick={this.scrollToElement}>
                    {intl.formatMessage({ id: 'global.contact', defaultMessage: 'Contact' })}
                  </a>
                </li>
              )}
              {lawyer.publications.length > 0 && (
                <li>
                  <a href="#lawyer-publications" onClick={this.scrollToElement}>
                    {intl.formatMessage({ id: 'lawyer.publications', defaultMessage: 'Publications' })}
                  </a>
                </li>
              )}
              {lawyer.speakingEngagements.length > 0 && (
                <li>
                  <a href="#lawyer-speaking-engagements" onClick={this.scrollToElement}>
                    {intl.formatMessage({ id: 'lawyer.speakings', defaultMessage: 'peaking Engagements' })}
                  </a>
                </li>
              )}
            </ul>
          </ModAnimation>
        </div>

        {lawyer.quotes && <ModBlockQuote content={lawyer.quotes} />}

        <div className="lawyer__info" id="lawyer-about">
          <ModBlockTitle
            content={stringToBlockTitleObject(intl.formatMessage({ id: 'lawyer.about', defaultMessage: 'About' }))}
          ></ModBlockTitle>
          {getLawyerDescription()}
          {getLawyerRecentWorkHighlights()}
          {getLawyerLanguages()}
          {getLawyerCurriculum()}
          {getLawyerMemberships()}
          <div className="grid align-center lawyer__info__element">
            <ModAnimation className="cell small-24 large-20 xlarge-18">
              <span className="label">
                <FormattedMessage id="lawyer.admission" defaultMessage="Admission" />
              </span>
              <FormattedMessage id={`lawyer.${lawyer.contact.admission}`} defaultMessage={lawyer.contact.admission} />
            </ModAnimation>
          </div>
        </div>

        <ModBlockNewsPublicationsGrid
          currentLanguage={currentLanguage}
          id={'lawyer-deals-cases'}
          content={createBlockNewsPublicationsGrid(
            BlockNewsPublicationsGridType.news,
            lawyer.contact.firstName +
              ' ' +
              lawyer.contact.lastName +
              (lawyer.contact.lastName.endsWith('s') ? "'" : "'s") +
              ' ' +
              'Deals, Cases & News',
            lawyer.news,
            lawyer.newsLink
          )}
        />

        {lawyer.clientQuote && <ModBlockQuote content={lawyer.clientQuote} />}

        {practicesArray.length > 0 || sectorsArray.length > 0 ? (
          <div className={'lawyer__expertise'} id="lawyer-expertise">
            <ModBlockTitle
              content={stringToBlockTitleObject(
                practicesArray.length > 1
                  ? intl.formatMessage({ id: 'global.practice-areas', defaultMessage: 'Practice Areas' })
                  : intl.formatMessage({ id: 'global.practice-area', defaultMessage: 'Practice Area' })
              )}
            ></ModBlockTitle>
            {getPractices()}
            {getSectors()}
          </div>
        ) : (
          ''
        )}

        <div className="lawyer__contact" id="lawyer-contact">
          <ModBlockTitle
            content={stringToBlockTitleObject(intl.formatMessage({ id: 'global.contact', defaultMessage: 'Contact' }))}
          ></ModBlockTitle>
          {/* <ModAnimation className="cell small-24 large-20 xlarge-18">
            <span className="label">Contact</span>
          </ModAnimation> */}
        </div>
        <div className="grid align-center">
          <div className="cell small-24 large-20 xlarge-18">
            <div className="grid">
              <ModAnimation className="cell small-24 medium-20 large-8 lawyer__contact__element">
                <p>
                  <strong>
                    {lawyer.contact.firstName} {lawyer.contact.lastName}
                  </strong>
                  <br />
                  {lawyer.contact.phone && (
                    <>
                      {' '}
                      {intl.formatMessage({ id: 'lawyer.phoneNumber', defaultMessage: 'P' }) + ': '}
                      <a href={'tel:' + lawyer.contact.phone}>{lawyer.contact.phone}</a>
                      <br />{' '}
                    </>
                  )}
                  {lawyer.contact.fax && (
                    <>
                      {' '}
                      {'F: ' + lawyer.contact.fax} <br />{' '}
                    </>
                  )}
                  {lawyer.contact.mobile && (
                    <>
                      {'M: ' + lawyer.contact.mobile} <br />{' '}
                    </>
                  )}
                  {lawyer.contact.email && (
                    <>
                      <a href={'mailto:' + lawyer.contact.email}>{lawyer.contact.email}</a>
                    </>
                  )}
                </p>
              </ModAnimation>

              {getLawyerOffices}

              <ModAnimation className="cell small-24 medium-20 large-8 lawyer__contact__element">
                <p>
                  <a className="link link--download" href={vCardLink}>
                    <ModSvg icon="download" />
                    vCard
                  </a>
                  <br />
                  <a className="link link--external" href={`/api/pdf?filename=${pdfFileName}&url=${lawyer._i18nCL.relativeUrl}`}>
                    <ModSvg icon="url" />
                    <FormattedMessage id="lawyer.download" defaultMessage="Download CV [PDF]" />
                  </a>
                  <br />
                  {lawyer.contact.linkedIn && (
                    <>
                      <a className="link link--url" href={lawyer.contact.linkedIn}>
                        <ModSvg icon={'linkedin'} /> LinkedIn
                      </a>
                    </>
                  )}
                </p>
              </ModAnimation>
            </div>
          </div>
        </div>

        {getLawyerAssistants()}

        <ModBlockNewsPublicationsGrid
          currentLanguage={currentLanguage}
          id={'lawyer-publications'}
          content={createBlockNewsPublicationsGrid(
            BlockNewsPublicationsGridType.publications,
            lawyer.contact.firstName +
              ' ' +
              lawyer.contact.lastName +
              (lawyer.contact.lastName.endsWith('s') ? "'" : "'s") +
              ' ' +
              (lawyer.publications && lawyer.publications.length > 1
                ? intl.formatMessage({ id: 'lawyer.latest-publications', defaultMessage: 'Latest Publications' })
                : intl.formatMessage({ id: 'lawyer.latest-publication', defaultMessage: 'Latest Publication' })),
            lawyer.publications,
            lawyer.publicationsLink
          )}
        />

        <ModBlockNewsPublicationsGrid
          currentLanguage={currentLanguage}
          id={'lawyer-speaking-engagements'}
          content={createBlockNewsPublicationsGrid(
            BlockNewsPublicationsGridType.publications,
            lawyer.contact.firstName +
              ' ' +
              lawyer.contact.lastName +
              (lawyer.contact.lastName.endsWith('s') ? "'" : "'s") +
              ' ' +
              (lawyer.speakingEngagements && lawyer.speakingEngagements.length > 1
                ? intl.formatMessage({ id: 'lawyer.latest-speakings', defaultMessage: 'Latest Speaking Engagements' })
                : intl.formatMessage({ id: 'lawyer.latest-speaking', defaultMessage: 'Latest Speaking Engagement' })),
            lawyer.speakingEngagements,
            lawyer.speakingEngagementsLink,
            intl.formatMessage({ id: 'lawyer.speakings', defaultMessage: 'Speaking Engagements' })
          )}
        />
      </React.Fragment>
    )

    return (
      <div className="lawyer">
        <div className={'lawyer__content'}>
          {hero}
          {content}
        </div>
        <ModPrintView
          children={
            <React.Fragment>
              {heroPrint}
              {content}
            </React.Fragment>
          }
        ></ModPrintView>
      </div>
    )
  }
}

export default injectIntl(ModTemplateLawyer)
